import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`A little bit about me...`}</h3>
    <blockquote>
      <p parentName="blockquote">{`My early affinity for design and creation led me to pursue a career in computer science. I enjoy finding creative solutions to solve modern problems; software development is the medium through which my ideas translate into real-world products.`}</p>
      <p parentName="blockquote">{`I have professional experience working in CI/CD environments and have developed skills with PostgreSQL, Java, JUnit, Python, Spring, Javalin, and VueJs. Throughout various personal projects, I also have experience working with ReactJs, ExpressJs, and NoSQL.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      